<script setup>
import { ref } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})
const localData = ref({ ...props.data })

const { route, fullPath } = useRoute()

onMounted(() => {
  if (localData.value.props && localData.value.props.js) {
    try {
      const scriptCode = localData.value.props.js.trim()
      // console.log("Executing Script:", scriptCode)
      new Function(scriptCode)()
    } catch (error) {
      console.error('Error executing script:', error)
    }
  }
})
</script>

<template>
  <div>
    <div :id="data.id" ref="customJsComponentRef"></div>
  </div>
</template>
